/*Prealoder*/
#preloader {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 99999;
	transition: all 200ms ease;
	.spinner-border {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -15px;
	}
}
.preloader-hide {
	opacity: 0;
	pointer-events: none;
    transition:all 150ms ease!important;
}
.theme-light {
	#preloader {
		background-color: $color_white;
	}
}
.theme-dark {
	#preloader {
		background-color: $theme-dark-background;
	}
}
