/*--Footer--*/
.footer {
	padding: 20px 30px 10px 30px;
	margin-bottom: 0px !important;
	z-index: 11;
	strong {
		i {
			padding: 0px 5px;
		}
		font-size: 10px;
		display: block;
		text-align: center;
		font-weight: 700;
		margin-top: 0px;
		text-transform: uppercase;
	}
	.footer-text {
		font-size: 12px;
		line-height: 22px;
		text-align: center;
		padding: 5px 10px 0px 10px;
	}
	.footer-copyright {
		border-top: solid 1px rgba(0,0,0,0.05);
	}
	.footer-links {
		border-top: solid 1px rgba(0,0,0,0.05);
		padding-top: 13px;
		display: block;
		text-align: center;
		font-size: 10px;
		margin-top: 20px;
		margin-bottom: 10px;
		a {
			padding: 40px 5px 1px 5px;
		}
	}
	.footer-title {
		color: $color-dark;
		width: 100%;
		font-size: 28px;
		text-align: center;
		font-weight: 900;
		font-family: $font-heading-family;
	}
	.footer-logo {
		position: relative;
		width: 100%;
		left: auto;
		margin: 0 auto;
		color: $color-transparent;
		position: relative;
		width: 100%;
		left: auto;
		margin: 0 auto;
		span {
			display: none !important;
		}
	}
}
.footer-title {
	text-align: center;
	display: block;
}
.footer-socials {
	max-width: 240px;
	margin: 0px auto 30px auto;
	a {
		float: left;
		font-size: 14px;
		margin: 0px 4px;
		color: $color-white;
		border-radius: 10px;
		box-shadow: 0 2px 14px 0 rgba(0,0,0,0.08) !important;
		i {
			width: 38px;
			line-height: 38px;
			text-align: center;
			display: block;
			float: left;
		}
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
.footer-copyright {
	border-top: solid 1px rgba(255,255,255,0.03);
	text-align: center;
	margin-bottom: -5px;
	padding-top: 15px;
	font-size: 11px;
	line-height: 24px;
}
.footer-logo {
	span {
		display: none;
	}
}
