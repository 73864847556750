/*Menus & Action Sheets & Modals*/

$theme-dark-menu-hider: rgba(0, 0, 0, 0.7);
$theme-light-menu-hider: rgba(0, 0, 0, 0.35);

.offcanvas {
  border: none;
  transition: all 350ms ease !important;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  height: auto;
  max-height: auto;
  display: block;
}

.offcanvas-bottom {
  transform: translateY(110%);
}

.offcanvas-top {
  bottom: auto;
  height: auto;
  max-height: auto;
}

.theme-dark .offcanvas-backdrop,
.theme-dark .modal-backdrop {
  background-color: $theme-dark-menu-hider;
}
.theme-light .offcanvas-backdrop,
.theme-light .modal-backdrop {
  background-color: $theme-light-menu-hider;
}

/*Modal*/

.custom-modal .modal-content {
  border: 0px;
  background-color: transparent;
}

.fade .custom-modal {
  margin-top:10px;
  transform: scale(0, 0) !important;
  opacity: 0;
  transition: all 300ms ease !important;
}
.show .custom-modal {
  transform: scale(1, 1) !important;
  opacity: 1;
  transition: all 300ms ease !important;
}

.no-transition .offcanvas,
.no-transition label {
  transition: all 0ms ease !important;
}

.modal-dialog-centered {
  max-width: 100% !important;
}
.modal-dialog-scrollable {
  height: 100%;
}
