$card-over-bg-zindex:2;
$card-under-bg-zindex:1;

/*Extending Cards Features*/
.row{margin-bottom:$globalMargin;}
.card {
	transform: translate3d(0,0,0);
	background-size: cover;
	border: $rounded-0 !important;
	max-height: 150000px;
    margin-bottom:$globalMargin;
	background-position: center center !important;
}

.cover-slider .card{margin-bottom:0px!important;}
[data-card-height="cover"].card{margin-bottom:0px!important;}
.card-style {
	overflow: hidden;
	margin: 0px 16px $globalMargin 16px;
	border-radius: $rounded-card-style;
	border: none;
	box-shadow: $globalShadow;
	.card-body {
		padding: 15px;
	}
}
.card-style .card-overlay{
	margin:0px;
    border-radius: $rounded-card-style;
}

.card-body {
	z-index: $card-over-bg-zindex;
}
.card-header {
	z-index: $card-over-bg-zindex;
}
.card-footer {
	z-index: $card-over-bg-zindex;
}
.card-top, .card-bottom, .card-center{
	background-color:transparent!important;
}
.card-top {
	z-index: $card-over-bg-zindex;
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
}
.card-bottom {
	z-index: $card-over-bg-zindex;
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
}
.card-center {
	z-index: $card-over-bg-zindex;
	position: absolute;
    display:block;
	left: 0px;
	right: 0px;
	top: 50%;
	transform: translateY(-50%);
}
.card-overlay {
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	position: absolute;
	z-index: $card-under-bg-zindex;
}
.card .img-fluid{
    width:100%!important;
}
.card-image {
	display: block;
	width: 100%;
	min-height: 250px;
	overflow: hidden;
	position: absolute;
	width: 100%;
	img {
		transition: all 450ms ease;
	}
}
.card-overflow {
	overflow: visible !important;
	z-index: 20;
}
[data-card-height] {
	p {
		padding-bottom: 0px;
	}
}

/*Card Effects*/
.card-scale-image {
	transform: scale(1.05, 1.05) !important;
}
.card-rotate-image {
	transform: rotate(5deg) scale(1.2, 1.2) !important;
}
.card-grayscale-image {
	filter: grayscale(100%);
}
.card-blur-image {
	filter: blur(5px);
}
.card-hide-image {
	opacity: 0 !important;
}
.card-hide {
	div {
		transition: $globalTransitions;
	}
	* {
		transition: $globalTransitions;
	}
}
.polaroid-effect {
	background-color: $color-white;
	padding: 5px;
	border: solid 1px rgba(0,0,0,0.1);
}
.card-scale {
	* {
		transition: $globalTransitions;
	}
}
.card-rotate {
	* {
		transition: $globalTransitions;
	}
}
.card-grayscale {
	* {
		transition: $globalTransitions;
	}
}
.card-blur {
	* {
		transition: $globalTransitions;
	}
}

.card-overlay{background-position:center center; background-size: cover!important;}
.card-overlay-infinite{
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 2000px;
    height: 100%;
    z-index: 0;
    animation: infiniteBG 600s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background-size: cover;
    background-repeat: repeat;
    background-position: left bottom;
    transform: translateX(0px);
}

@keyframes infiniteBG {
	0% {transform:translateX(0px);}
	50% {transform:translateX(-1400px);}
	100% {transform:translateX(0px);}
}

.over-card{z-index:10; position:relative; overflow: visible!important;}
.under-slider{z-index:2; position:relative; padding-top:100px; transform:translateY(-120px); margin-bottom:-100px;}
.over-slider, .slider-over{z-index:5; position:relative!important;}


/*Shadow Cards - Added in Sticky 4.5*/
/*Shadow Backgrounds*/
.shadow-bg:after {
	content: '';
	position: absolute;
	top:0px;
	left:0px;
	width: 100%;
	height: 100%;
	background:inherit;
	z-index: -1;
}

.shadow-bg {background-size:cover; position:relative; z-index:10; overflow:visible!important;}
.shadow-bg-xs::after{transform: scale(0.75) translateY(5px) translateZ(0px); filter: blur(8px);}
.shadow-bg-s::after{transform: scale(0.80) translateY(5px) translateZ(0px); filter: blur(12px);}
.shadow-bg-m::after{transform: scale(0.85) translateY(7px) translateZ(0px); filter: blur(12px);}
.shadow-bg-l::after{transform: scale(0.95) translateY(10px) translateZ(0px); filter: blur(12px);}


.theme-dark .shadow-card:after{
	opacity:0.12!important;
}
.theme-dark #footer-bar .circle-nav-2::before,
.theme-dark .shadow-bg:after{
	opacity:0.5!important;
}

/*Shadow Cards*/
.shadow-card .card-top,
.shadow-card .card-center,
.shadow-card .card-bottom{
	z-index:2;
}
.shadow-card .card-overlay{
	z-index:1;
}
.shadow-card::before{
	background-image:inherit;
	content:'';
	top:0px;
	width:100%;
	height:100%;
	border-radius:inherit;
	background-size:cover;
	z-index:0;
}
.shadow-card::after {
	transform: scale(0.95) translateY(10px) translateZ(-36px);
	filter: blur(12px);
	content: '';
	position: absolute;
	top:0px;
	width: 100%;
	height: 100%;
	background-image: inherit;
	background-size: cover;
	background-color:inherit;
	z-index: -1;
}

.shadow-card {z-index:5; overflow:visible;}
.shadow-card .card-overlay{
	left:0px;
	top:0px;
	bottom:0px;
	right:0px;
}
.shadow-card-s::after{transform: scale(0.80) translateY(15px) translateZ(-26px); filter: blur(12px);}
.shadow-card-m::after{transform: scale(0.85) translateY(15px) translateZ(-36px); filter: blur(12px);}
.shadow-card-l::after{transform: scale(0.95) translateY(15px) translateZ(-46px); filter: blur(12px);}

//Dark Settings
.theme-light .shadow-card:after{opacity: 0.8;}
.theme-dark .shadow-card:after{opacity:0.3;}
