/*Pagination*/
.pagination {
	margin-bottom: $globalMargin;
	.page-link {
		padding: 10px 14px;
	}
	.page-item {
		margin-right: 5px;
		margin-left: 5px;
	}
}
.page-item.active {
	a {
		color: $color_white !important;
	}
}
