/*Gallery Views*/
.gallery-view-controls button{
    float:left;
    width:33.3%;
    text-align:center;
    height:50px;
    line-height:50px;
    font-size:16px;
    color:#1f1f1f;
}
.theme-dark .gallery-view-controls a{color:#FFF;}

.gallery-view-3{margin-bottom:-40px}
.gallery-view-1 a{width:30%; float:left;}
.gallery-view-1 .caption{display:none;}
.gallery-view-2 .caption{display:none;}
.gallery-view-3 .caption{padding-top:20px;}
.gallery-view-1 a{margin-bottom:5%;}
.gallery-view-1 a:nth-child(3n-1){margin:auto 5% auto 5%;}
.gallery-view-2 a{width:47.5%; margin-bottom:5%; float:left;}
.gallery-view-2 a:nth-child(2n-1){margin-right:5%;}



