/*Icons*/
.icon:hover{color:#FFF;}
.icon{display:inline-block!important; position:relative; text-align:center; padding:0px!important; border:0px}
.icon-xxs i{width:$icon-xxs; line-height:$icon-xxs; font-size:13px;}
.icon-xs i{width:$icon-xs; line-height:$icon-xs; font-size:14px;}
.icon-s i{width:$icon-s; line-height:$icon-s; font-size:15px;}
.icon-m i{width:$icon-m; line-height:$icon-m; font-size:16px;}
.icon-l i{width:$icon-l; line-height:$icon-l; font-size:17px;}
.icon-xl i{width:$icon-xl; line-height:$icon-xl; font-size:18px;}
.icon-xxl i{width:$icon-xxl; line-height:$icon-xxl; font-size:19px;}
.icon-center{display:block; margin:0 auto;}
