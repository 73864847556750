/*Range Sliders*/
.ios-slider {
	height: 40px !important;
	background: linear-gradient(to right, #5D9CEC 0%, #5D9CEC 100%);
	background-size: 100% 3px;
	background-position: center;
	background-repeat: no-repeat;
	&::-webkit-slider-thumb {
		background-color: $color_white !important;
		border: solid 1px rgba(0,0,0,0.1);
		border-radius: 30px;
		height: 25px !important;
		width: 25px !important;
		box-shadow: -3px 3px 10px 1px rgba(0,0,0,0.2) !important;
	}
}
.material-slider {
	height: 40px !important;
	background: linear-gradient(to right, #CCD1D9 0%, #CCD1D9 100%);
	background-size: 100% 18px;
	background-position: center;
	background-repeat: no-repeat;
	padding: 0px 3px 0px 3px;
	&::-webkit-slider-thumb {
		background-color: $color_dark !important;
		border: solid 1px rgba(0,0,0,0.1);
		height: 10px !important;
		width: 30px !important;
	}
}
.classic-slider {
	height: 20px !important;
	background-color: $color_white;
	border: solid 1px rgba(0,0,0,0.1);
	border-radius: 30px !important;
	background-size: 100% 25px;
	background-position: center;
	background-repeat: no-repeat;
	background-color: $color_transparent;
	padding: 0px 3px 0px 3px;
	-webkit-box-shadow: inset 0 2px 2px 1px rgba(0,0,0,0.1);
	box-shadow: inset 0 2px 2px 1px rgba(0,0,0,0.1);
	&::-webkit-slider-thumb {
		background-color: $blue !important;
		border: solid 1px rgba(0,0,0,0.0);
		height: 16px !important;
		border-radius: 20px;
		width: 16px !important;
		box-shadow: -3px 3px 10px 1px rgba(0,0,0,0.2) !important;
	}
}
.range-slider-icons {
	padding: 0px 30px 0px 30px;
}
.range-slider.range-slider-icons {
	i {
		position: absolute;
		top: 0px;
		height: 44px;
		line-height: 44px;
		text-align: center;
	}
}
.range-slider {
	position: relative;
	.fa-range-icon-1 {
		left: 0px;
	}
	.fa-range-icon-2 {
		right: 0px;
	}
}
