/*Map Full*/
.map-full .card-overlay{transition:all 350ms ease; z-index:2;}
.map-full .card-center{z-index:3;}

.hide-map{
	position:absolute;
	margin-top:-120px;
	width:200px;
	left:50%;
	margin-left:-100px;
    text-align:center;
    z-index:2;
}
.map-full .card{
    z-index:2;
    position:absolute;
    top:50px;
    left:0px;
    right:0px;
    bottom:0px;
}
.map-full iframe{position:absolute; width:100vw; height:100vh;}
.activate-map{opacity:1!important; transition:all 250ms ease; transition:all 350ms ease;}
.deactivate-map{opacity:0!important; pointer-events: none; transition:all 350ms ease;}

