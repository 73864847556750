/*Offline Detector*/
.offline-message {
	position: fixed;
	transform: translateY(-200%);
	padding-bottom: 0px;
	text-align: center;
	text-transform: uppercase;
	font-weight: 900;
	transition: $globalTransitions;
	top: 0px;
	left: 0px;
	right: 0px;
	z-index: 9999;
	line-height: 55px;
}
.online-message {
	position: fixed;
	transform: translateY(-200%);
	padding-bottom: 0px;
	text-align: center;
	text-transform: uppercase;
	font-weight: 900;
	transition: $globalTransitions;
	top: 0px;
	left: 0px;
	right: 0px;
	z-index: 9999;
	line-height: 55px;
}
.is-on-homescreen {
	.offline-message {
		padding-top: 30px !important;
	}
	.online-message {
		padding-top: 30px !important;
	}
}
.offline-message-active {
	transform: translateY(0%);
}
.online-message-active {
	transform: translateY(0%);
}
